




// ----------------------------------------------- variables --------------------------------------  //

$marginside: 5vw;


// ----------------------------------------------- algemeen ------------------------------------------------ //
html {
	p b, p strong {
		font-family: $body-font-family; }
	h6 {
		font-family: $body-font-family;
		font-weight: bold; }
	ul {
		list-style: none;
		list-style-type: none;
		margin-left: 0; }
	.nowrap {
		white-space: nowrap; } }



// ----------------------------------------------- header & navigation ------------------------------------------------ //


out-nav#mainNav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #f7f7f7;
	text-align: center;
	//border-bottom: 1px solid #ccc
	ul.main {
		margin-bottom: 0;
		margin-top: 10px;
		li {
			display: inline-block;
			vertical-align: top;
			text-align: left;
			width: 15%;
			ul.sub {
				max-height: 0px;
				padding-left: 10px;
				margin-left: 0;
				margin-top: 10px;
				overflow-y: hidden;
				border-left: 1px solid #ccc;
				transition: all 0.75s;
				li {
					display: block;
					padding: 0;
					height: 20px;
					vertical-align: top;
					transition: all 0.75s;
					transition: margin-left 1s 0.7s;
					margin-left: 100%;
					&:nth-child(1) {
						margin-top: -5px; } } } } }
	&:hover {
		ul.main {
			li {
				ul.sub {
					max-height: 180px;
					margin-bottom: 15px;
					transition: all 0.75s;
					overflow-x: hidden;
					li {
						margin-left: 0%;
						transition: all 0.75s;
						&:nth-child(2) {
							transition-delay: 0.1s; }
						&:nth-child(3) {
							transition-delay: 0.2s; }
						&:nth-child(4) {
							transition-delay: 0.3s; }
						&:nth-child(5) {
							transition-delay: 0.4s; }
						&:nth-child(6) {
							transition-delay: 0.5s; }
						&:nth-child(7) {
							transition-delay: 0.6s; }
						&:nth-child(8) {
							transition-delay: 0.7s; }
						&:nth-child(9) {
							transition-delay: 0.8s; }
						&:nth-child(10) {
							transition-delay: 0.9s; } } } } } } }
div#language {
	text-align: right;
	margin-right: $marginside;
	ul {
		background-color: $secondary-color;
		padding: 3px 7px;
		width: auto;
		border-radius: 0 0 12px 12px;
		display: inline-block;
		li {
			text-transform: uppercase;
			font-family: $header-font-family;
			color: $white;
			display: inline;
			padding: 0;
			&:after {
				content: " | "; }
			&:last-child:after {
				content: ""; }
			a {
				transition: color 1s;
				color: $white;
				&:hover {
					color: $primary-color; } }
			&.active {
				font-weight: bold; } } }
	@include breakpoint(large) {
		margin-right: $marginside*2; } }

div#toprow {
	position:relative {}
	text-align: right;
	div#logo {
		position: absolute;
		left: $marginside;
		width: 52vw;
		max-width: 300px;
		vertical-align: top;
		@include breakpoint(large) {
			left: $marginside*2; } }
	nav#mainNav {
		margin-right: $marginside;
		@include breakpoint(large) {
			margin-right: $marginside*2; }
		ul#mainMenu {
			vertical-align: top;
			li {
				padding: 0;
				line-height: 120%;
				a {
					text-transform: uppercase;
					display: inline-block;
					transition: all 1s;
					&.active {
						font-weight: bold;
						color: $secondary-color; }
					&:hover {
						color: $secondary-color;
						transform: translateX(10px); } } } } } }




.topimage {
	border-radius: 500px 0 0 500px;
	overflow: hidden;
	margin-left: $marginside;
	img {
		width: 100%; }
	@include breakpoint(large) {
		margin-left: $marginside*2; } }

// ------------------------------------------ content ------------------------------------- //

.pageContent {
	&__back {
		margin-top: $marginside/2;
		margin-left: $marginside;
		margin-right: $marginside;
		p {
			margin-bottom: 0; }
		@include breakpoint(large) {
			margin-left: $marginside*2;
			margin-right: $marginside*2; }
		img {
			background-color: $primary-color;
			width: 30px;
			border-radius: 30px;
			padding: 5px; }
		a {
			&:hover {
				img {
					background-color: $secondary-color; } } } }
	&__title, &__callout {
		margin-right: $marginside;
		margin-top: 20px;
		margin-bottom: 20px;
		border-radius: 0 500px 500px 0;
		@include breakpoint(large) {
			margin-right: $marginside*2; }
		h1, h2, h3, h4 {
			padding-top: 0.5rem;
			color: $white;
			margin-left: $marginside;
			@include breakpoint(large) {
				margin-left: $marginside*2; } }
		@include breakpoint(medium) {
			margin-top: 40px;
			margin-bottom: 40px; } }
	&__title {
		background-color: $secondary-color; }
	&__callout {
		background-color: $primary-color;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 50px;
		h1 {
			color: $white;
			margin-bottom: 0; }
		h4 {
			padding-top: 0;
			color: $secondary-color; } }
	&__body, &__homepageicons {
		margin-left: $marginside;
		margin-right: $marginside;
		position: relative;
		@include breakpoint(large) {
			margin-left: $marginside*2;
			margin-right: $marginside*2; }
		ul {
			li {
				margin-bottom: 1rem;
				line-height: 1.5; } }
		&:after {
			content: "";
			clear: both;
			display: table; }
		&--home {
			h2 {
				color: $secondary-color;
				@include breakpoint(medium) {
					font-size: 2rem; } }
			h3 {
				color: $primary-color;
				padding-top: 1rem;
				padding-bottom: 1rem;
				@include breakpoint(medium) {
					font-size: 1.5rem; } } }
		&__block {
			margin-bottom: 1rem;
			vertical-align: top;
			&:after {
				content: "";
				clear: both;
				display: table; }
			&__left {
				&--menu {
					margin-right: 0;
					margin-bottom: 30px; } }
			&__right {
				@include breakpoint(medium) {
					padding-right: $marginside; }
				@include breakpoint(large) {
					padding-right: $marginside/2; }
				hr {
					border-top: 1px solid $primary-color;
					width: 100%;
					max-width: 100%; }
				&--productlist {
					@include breakpoint(medium);
					img.readmore {
						// zie ook css bovenaan de pagina
						background-color: $primary-color;
						border-radius: 30px;
						border: 5px solid $primary-color;
						height: 30px;
						width: 30px;
						float: right;
						margin: 0 0 10px 0;
						&:hover {
							background-color: $secondary-color;
							border: 5px solid $secondary-color; } }
					h2 a:hover {
						color: $secondary-color; } }
				&--images {
					&__content {
						img {
							border-radius: 20px 20px 0 0;
							width: 100%; }
						p {
							background-color: $secondary-color;
							padding: 8px 20px 8px 20px;
							color: $white;
							border-radius: 0 0 20px 20px; }
						@include breakpoint(small-medium) {
							width: 50%;
							display: inline-block;
							&:nth-child(odd) {
								padding-right: 10px; }
							&:nth-child(even) {
								padding-left: 10px; } } } }

				&--contact {
					position: relative;
					#contact-form {
						p {
							b, strong, h1, h2, h3, h4, h6 {
								color: $primary-color; } }
						.form__item {
							label {
								color: $primary-color;
								text-transform: uppercase; }
							input, textarea {
								margin-bottom: 10px;
								width: 100%;
								background-color: #d7eae8;
								outline: none;
								boxshadow: none;
								border-style: none;
								padding: 10px;
								border-radius: 5px;
								&:focus {
									outline: none;
									boxshadow: none; } } }
						#wrap_Inputfield_scf-website {
							display: none; }
						#Inputfield_submit {
							background-color: $primary-color;
							padding: 10px 20px;
							border-radius: 50px;
							color: $white;
							text-transform: uppercase; } } } }


			@include breakpoint(medium) {
				margin-bottom: 3rem;
				margin-left: -2rem;
				margin-right: -2rem;
				&__left {
					width: 25%;
					float: left;
					display: block;
					text-align: right;
					margin-right: 1.5rem;
					margin-top: 1px;
					height: 100%;
					&--menu {
						text-align: left;
						width: 315px;
						margin-right: 0;
						margin-bottom: 0;
						margin-right: 20px; } }
				&__right {
					width: 70%;
					float: right;
					&--productlist {
						width: calc(100% - 315px - 20px); //breedte linker kolom + marge rechts
						min-height: 600px; } } }
			&__productrow {
				@include breakpoint(medium) {
					display: table;
					width: 100%;
					border-spacing: 2rem; } }
			&__productgroup {
				border-radius: 0 30px;
				margin-bottom: 20px;
				vertical-align: top;
				margin-top: 3rem;
				div.categorieicon {
					width: 70px;
					height: 0px;
					margin-left: 50%;
					img {
						margin-left: -50%;
						margin-top: -50%;
						border: 2px solid $white;
						border-radius: 70px; } }
				&__title {
					text-align: center;
					border-radius: 0 28px 0 0;
					padding-top: 35px;
					h2 {
						color: $white;
						font-family: $body-font-family;
						font-weight: bold;
						font-size: 1.2rem;
						line-height: 2.2rem;
						@include breakpoint(medium) {
							font-size: 1.5rem;
							line-height: 3.2rem; } } }
				ul {
					margin: 1rem;
					padding-bottom: 1rem;
					li {
						margin: 0;
						display: table;
						border-spacing: 0;
						width: 100%;
						a {
							color: $black; }
						img.arrow {
							width: 20px;
							border-radius: 30px;
							padding: 3px;
							margin-right: 5px;
							margin-top: -2px; }
						p {
							display: inline-block;
							margin-bottom: 3px;
							&:not:first-child {}
							margin-top: 3px;
							&.artikelcode {
								float: right;
								&:after {
									content: "";
									clear: both;
									display: table; } } }
						&:hover {
							img.arrow {
								margin-left: 10px; }
							p {
								font-weight: bold; } } } }
				@include breakpoint(medium) {
					width: 49%;
					display: table-cell; } } }


		.iconlink {
			border-radius: 50px;
			width: 100%;
			height: 40px;
			margin-bottom: 10px;
			&:after {
				content: "";
				clear: both;
				display: table; }
			p {
				padding-left: 1rem;
				line-height: 40px;
				color: $white;
				display: inline-block; }
			img.icons {
				float: right;
				display: inline-block;
				width: 46px;
				border-radius: 40px;
				border: solid 3px $white;
				margin-top: -3px;
				margin-right: -36px; }
			img.arrow {
				margin: 5px 5px 0 30px;
				width: 0px;
				float: right; }
			&:hover, &.active {
				p {
					font-weight: bold; } }
			@include breakpoint(medium) {
				width: 270px;
				&:hover, &.active {
					width: 305px;
					img.arrow {
						width: 30px;
						margin: 5px 3px 0 37px; } } } } }
	&__homepageicons {
		margin-top: 1.5rem;
		@include breakpoint(medium) {
			position: relative; }
		&__block {
			margin-bottom: 0;
			&__categorieicon {
				display: inline-block;
				height: 130px;
				width: 50%;
				text-align: center;
				img {
					display: block;
					margin-left: auto;
					margin-right: auto;
					width: 60px; }
				p {
					font-size: 0.8rem;
					padding-top: 10px;
					line-height: 1; }
				@include breakpoint(small-medium) {
					width: 33%; }
				@include breakpoint(medium) {
					p {
						font-size: 1rem; } }
				@include breakpoint(large) {
					width: 25%; }
				@include breakpoint(xlarge) {
					width: 20%; }
				@include breakpoint(xxlarge) {
					width: 16%; } } } } }

// ------------------------------------------ footer ------------------------------//
footer {
	background-color: $primary-color;
	margin-top: 20px;
	overflow-x: hidden;
	@include breakpoint(large) {
		margin-top: 40px; }
	svg {
		display: block;
		margin-top: -1px; }
	.footercontent {
		color: $white;
		padding-top: 20px;
		padding-bottom: 40px;
		margin-left: $marginside;
		margin-right: $marginside;
		position: relative;
		@include breakpoint(large) {
			margin-left: $marginside*2;
			margin-right: $marginside*2; }
		a {
			color: $white;
			transition: color 1s;
			&:hover {
				color: $secondary-color; } }
		&__left {
			display: inline-block;
			p {
				margin-bottom: 0; } }
		&__right {
			@include breakpoint(xlarge) {
				text-align: right;
				display: inline-block;
				position: absolute;
				right: 0;
				top: 0;
				p {
					margin-bottom: 0; } } } } }






